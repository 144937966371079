import '@styles/main.scss';
import '@styles/common/tailwind.css';
import TestimonialsSwiper from './components/testimonials-swiper';
import ServiceSwiper from './components/services-swiper';
import LogossSwiper from './components/logos-swiper';

// @ts-ignore
import StickyEvents from 'sticky-events';

class App {
    constructor() {

        new TestimonialsSwiper();
        new ServiceSwiper();
        this.searchForm();
        new LogossSwiper();
        this.menu();
        this.navMenu();
    }

    navMenu() {
        const stickyEvents = new StickyEvents();

        const { stickyElements } = stickyEvents;

        stickyElements.forEach(sticky => {
            sticky.addEventListener(StickyEvents.CHANGE, (event) => {
                sticky.classList.toggle('is-fixed', event.detail.isSticky);
            });
        });
    }

    menu() {
        const trigger = document.getElementById('mobile-menu-trigger');
        const menu = document.getElementById('menu-primary-menu');

        trigger.addEventListener('click', () => {
            menu.classList.toggle('is-active');
            trigger.classList.toggle('is-active');
            document.documentElement.classList.toggle('has-menu-open');
        });
    }

    searchForm() {
        const trigger = document.querySelector('.race-search-link')
        const overlay = document.getElementById('search-overlay')
        const form = document.querySelector('.search-form');

        if(form === null) return;
        if(overlay === null) return;
        if(trigger === null) return;

        trigger.addEventListener('click', e => {
            e.preventDefault();

            overlay.classList.remove('is-hidden');
        })    

        overlay.addEventListener('click', () => overlay.classList.add('is-hidden'));

        form.addEventListener('click', e => e.stopPropagation());
    }
}

document.addEventListener('DOMContentLoaded', () => {
    new App();
});
